import { version, createApp } from "vue";
import App from "./App.vue";
import router from "@/router/router";
import Quasar from 'quasar/src/vue-plugin.js';;

import quasarUserOptions from "./quasar-user-options";

console.log("Vue version " + version);

try {
  const app = createApp(App);
  app.use(router);
  app.use(Quasar, quasarUserOptions);
  app.mount("#app");
} catch (e) {
  document.getElementById("apperr").innerHTML = "Error: " + e;
}
