import { createRouter, createWebHistory  } from 'vue-router'


const Panel = () => import('@/layouts/Panel')
const Privacy = () => import('@/pages/Privacy')
//const Pricing = () => import('@/pages/Pricing')
const Main = () => import('@/pages/Main')
const Done = () => import('@/pages/Done')
const FAQ = () => import('@/pages/FAQ')
const About = () => import('@/pages/About')
const WhatAmI = () => import('@/pages/WhatAmI')
const NotFound = () => import('@/pages/NotFound')


const router = createRouter({
	linkActiveClass: 'active',

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash };
    }
    return { x: 0, y: 0 };
  },
  history: createWebHistory(),
	routes: configRoutes()
})


function configRoutes() {
	return [
		{
			path: '/',
			name : 'Panel',
			component: Panel,
      children: [
        {
          name: 'Privacy', path: 'privacy', component: Privacy, props: true
        },
        {
          name: 'About', path: 'about', component: About, props: true
        },
        {
          name: 'FAQ', path: 'faq', component: FAQ, props: true
        },
        {
          name: 'Main', path: '', component: Main, props: true
        },
        {
          name: 'Done', path: 'ok', component: Done, props: true
        },
        {
          name: 'WhatAmI', path: 'whatami', component: WhatAmI, props: true
        },
        {
          name: 'About', path: 'about', component: About, props: true
        },
      ]
		},
    {
      path: "/:catchAll(.*)",
      component: NotFound,
    }

	]
}

export default router

