<template>
  <div id="app">
    <div v-if="!globalError">
      <router-view/>
    </div>
    <div id="apperr" class="error" v-else>
      VC Error: {{globalError}}
    </div>
  </div>
</template>

<style lang="scss">

</style>


<script>

export default {
  data() {
    return {
      globalError: ""
    }
  },
  mounted() {
  },
  methods: {
    globalClick: function() {
 //     this.$store.dispatch('click', 'xxxxxxxxxxxxx')
    }

  }

}
</script>


<style>
#apperr {
  color: red;
  margin: 10px;
  font-size: 1.4rem;
  padding: 20px;
}
</style>
